<template>
  <div class="page_wrapper">
    <div class="page_content">
      <img src="@/assets/images/clock.png" />
      <span class="title">审核已提交</span>
      <p class="description">我们会在3个工作日内完成审核， 审核结果会通过中微信通公众号进行通知</p>
      <vant-button class="done-button" @click="handleConfirm">确定</vant-button>
    </div>
  </div>
</template>
<script>
import { Button as vantButton } from "vant";
export default {
  components: {
    vantButton
  },
  methods: {
    handleConfirm() {
      this.$router.push('/');
    }
  }
}
</script>
<style lang="less" scoped>
.page_wrapper {
  background: #5AA16C; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .page_content {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;

    img {
      width: 80px;
    }

    .title {
      font-size: 17px;
      margin-top: 18px;
    }

    .description {
      font-size: 15px;
      margin-top: 5px;
      text-align: center;
    }

    .done-button {
      width: 180px;
      height: 40px;
      background: #FFFCCB;
      border-radius: 4px;
      color: #A69352;
      font-size: 15px;
    }
  }
}
</style>